import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServiceSidebar from "../../components/ServiceSidebar";
import ServiceSMCard from "../../components/ServiceSMCard";
import ServiceSupport from "../../components/ServiceSupport";

const DevSoft = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href="https://odidor.co/SoftwareDevelopment" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Software Development Agency | Software Development Firm</title>
        <meta
          name="description"
          content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project"
        />
        <meta
          name="keywords"
          content="POS Software development, Customized HRMS developments,Inventory software solutions and development, software development agency"
        />
        <meta
          property="og:title"
          content="Software Development Agency | Software Development Firm"
        />
        <meta
          property="og:description"
          content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/SoftwareDevelopment"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/SoftDev.jpg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta
          name="twitter:title"
          content="Software Development Agency | Software Development Firm"
        />
        <meta
          name="twitter:description"
          content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project"
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/SoftDev.jpg"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta
          property="og:title"
          content="Software Development Agency | Software Development Firm"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/SoftDev.jpg"
        />
        <meta
          property="og:url"
          content="https://odidor.co/SoftwareDevelopment"
        />
      </Helmet>
      <main>
        <Header />
        <div class="bg-shape">
          <img
            src="../assets/img/shape/shape-1.png"
            alt="Odidor  app development"
          />
        </div>

        <section class="page__title-area  pt-50">
          <div class="container">
            <div class="row">
              <div class="col-xxl-12">
                <div class="page__title-content mb-50">
                  <h1 class="page__title">Software Development Solutions</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        software Development{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="product__area pb-115">
          <div class="container">
            <div class="row">
              <div class="col-xxl-8 col-xl-8 col-lg-8">
                <div class="product__wrapper">
                  <div class="product__details-thumb w-img mb-30">
                    <img
                      src="../assets/img/services/AppDev.jpg"
                      alt="App Development"
                    />
                  </div>

                  <div class="postbox__details mb-30">
                    <p class="drop-cap">
                      We blend creativity with exceptional service to deliver
                      unique solutions tailored to meet the needs of our
                      clients. What sets us apart is our commitment to
                      understanding and realizing your vision. We don't just
                      implement ideas; we transform them into tangible outcomes
                      that exceed expectations. From planning to execution, our
                      dedicated team is with you every step of the way, ensuring
                      outstanding results.
                    </p>
                  </div>

                  <div class="postbox__details mb-30">
                    <h2 class="mb-30">Our Expertise in Custom Solutions</h2>
                    <h3>Personalized Services</h3>
                    <p>
                      Our team specializes in crafting custom solutions tailored
                      to your unique requirements. We focus on delivering
                      outcomes that reflect your goals and values while
                      providing a seamless and delightful experience for your
                      audience.
                    </p>
                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">
                        Why choose our personalized approach
                      </h3>
                      <ol>
                        <li>
                          <p>
                            You want a solution that resonates with your
                            audience and creates lasting impressions.
                          </p>
                        </li>
                        <li>
                          <p>
                            You value a scalable and flexible foundation that
                            can grow with your aspirations.
                          </p>
                        </li>
                        <li>
                          <p>
                            You require precise and innovative strategies to
                            bring your vision to life while addressing specific
                            challenges.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="product__features mt-10">
                    <h3 className="product__overview-title">Our Expertise:</h3>

                    {/* Enterprise Software Development */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/enterprise-software-development"
                        title="Enterprise Software Development"
                      >
                        <div
                          className="documentation__item mb-30 text-center"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff7eb3, #ff758c)", // Replace with your pink gradient
                          }}
                        >
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-cogs fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/enterprise-software-development"
                                title="Enterprise Software Development"
                              >
                                Enterprise Software Development
                              </a>
                            </h3>
                            <p className="text-light">
                              Tailored solutions for complex business needs,
                              improving workflows, scalability, and performance.
                            </p>
                            <a
                              href="/services/software-development/enterprise-software-development"
                              title="Enterprise Software Development"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* Custom Software Development */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/custom-software-development"
                        title="Custom Software Development"
                      >
                        <div
                          className="documentation__item mb-30 text-center"
                          style={{
                            background:
                              "linear-gradient(135deg, #007bff, #0056b3)", // Replace with your blue gradient
                          }}
                        >
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-laptop-code fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/custom-software-development"
                                title="Custom Software Development"
                              >
                                Custom Software Development
                              </a>
                            </h3>
                            <p className="text-light">
                              Crafting personalized software tailored to your
                              unique business goals and challenges.
                            </p>
                            <a
                              href="/services/software-development/custom-software-development"
                              title="Custom Software Development"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                  

                    {/* Cloud Software Solutions */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/cloud-software-solutions"
                        title="Cloud Software Solutions"
                      >
                        <div
                          className="documentation__item mb-30 text-center"
                          style={{
                            background:
                              "linear-gradient(135deg, #84fab0, #8fd3f4)", // Gradient applied here
                            borderRadius: "8px",
                            padding: "20px",
                          }}
                        >
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-cloud fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/cloud-software-solutions"
                                title="Cloud Software Solutions"
                              >
                                Cloud Software Solutions
                              </a>
                            </h3>
                            <p className="text-light">
                              Scalable and secure cloud-based applications
                              designed to streamline your operations.
                            </p>
                            <a
                              href="/services/software-development/cloud-software-solutions"
                              title="Cloud Software Solutions"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* Embedded Software Development */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/embedded-software-development"
                        title="Embedded Software Development"
                      >
                        <div className="documentation__item gradient-purple mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-microchip fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/embedded-software-development"
                                title="Embedded Software Development"
                              >
                                Embedded Software Development
                              </a>
                            </h3>
                            <p className="text-light">
                              Smart solutions for embedded systems in diverse
                              industries, ensuring high performance and
                              reliability.
                            </p>
                            <a
                              href="/services/software-development/embedded-software-development"
                              title="Embedded Software Development"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    
                    {/* Gaming & Multimedia Software */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/gaming-multimedia-software"
                        title="Gaming & Multimedia Software"
                      >
                        <div
                          className="documentation__item mb-30 text-center"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff758c, #ff7eb3)", // Gradient for Gaming & Multimedia Software
                            borderRadius: "8px",
                            padding: "20px",
                          }}
                        >
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-gamepad fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/gaming-multimedia-software"
                                title="Gaming & Multimedia Software"
                              >
                                Gaming & Multimedia Software
                              </a>
                            </h3>
                            <p className="text-light">
                              Interactive and immersive software solutions for
                              gaming and multimedia experiences.
                            </p>
                            <a
                              href="/services/software-development/gaming-multimedia-software"
                              title="Gaming & Multimedia Software"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* Cybersecurity Software Development */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/cybersecurity-software-development"
                        title="Cybersecurity Software Development"
                      >
                        <div
                          className="documentation__item mb-30 text-center"
                          style={{
                            background:
                              "linear-gradient(135deg, #434343, #000000)", // Gradient for Cybersecurity Software Development
                            borderRadius: "8px",
                            padding: "20px",
                          }}
                        >
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-shield-alt fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/cybersecurity-software-development"
                                title="Cybersecurity Software Development"
                              >
                                Cybersecurity Software Development
                              </a>
                            </h3>
                            <p className="text-light">
                              Protect your systems and data with robust
                              cybersecurity applications.
                            </p>
                            <a
                              href="/services/software-development/cybersecurity-software-development"
                              title="Cybersecurity Software Development"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* Educational & E-Learning Software */}
                    <div className="col-12">
                      <a
                        href="/services/software-development/educational-and-e-learning-software"
                        title="Educational & E-Learning Software"
                      >
                        <div className="documentation__item gradient-yellow mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i className="fas fa-graduation-cap fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/software-development/educational-and-e-learning-software"
                                title="Educational & E-Learning Software"
                              >
                                Educational & E-Learning Software
                              </a>
                            </h3>
                            <p className="text-light">
                              E-learning platforms that engage and empower
                              learners through interactive and intuitive
                              interfaces.
                            </p>
                            <a
                              href="/services/software-development/educational-and-e-learning-software"
                              title="Educational & E-Learning Software"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="postbox__details mb-30">
                    <h3>Custom Software Development</h3>
                    <p>
                      Our expertise in software development enables us to create
                      tailor-made solutions that align perfectly with your
                      business needs. From enterprise software to scalable web
                      applications, we leverage the latest technologies to
                      deliver robust and efficient products. Our client-focused
                      approach ensures each project is executed with precision
                      and dedication.
                    </p>
                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">
                        Why choose custom software solutions?
                      </h3>
                      <ol>
                        <li>
                          <p>
                            You need unique features and functionality tailored
                            to your business processes.
                          </p>
                        </li>
                        <li>
                          <p>
                            Scalability and flexibility are priorities for
                            future growth.
                          </p>
                        </li>
                        <li>
                          <p>
                            You want to maintain a competitive edge with
                            innovative technology.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="postbox__details mb-30">
                    <h3>Web Application Development</h3>
                    <p>
                      We specialize in building secure, scalable, and
                      high-performing web applications using frameworks like
                      React, Angular, and Next.js. From interactive user
                      interfaces to seamless backend integrations, our team
                      ensures that your web application exceeds expectations.
                    </p>
                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">
                        Key benefits of our web application services:
                      </h3>
                      <ol>
                        <li>
                          <p>
                            Fast-loading, responsive, and SEO-optimized web
                            solutions.
                          </p>
                        </li>
                        <li>
                          <p>
                            Integration with APIs and third-party services for
                            enhanced functionality.
                          </p>
                        </li>
                        <li>
                          <p>
                            Regular updates and support for continuous
                            improvement.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="postbox__details mb-30">
                    <h3>Mobile App Development</h3>
                    <p>
                      Whether it’s a native app or a cross-platform solution, we
                      bring your mobile vision to life. Our team uses
                      cutting-edge technologies like Kotlin for Android, Swift
                      for iOS, and Flutter or React Native for cross-platform
                      development, ensuring the best user experience across
                      devices.
                    </p>
                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">
                        Our mobile app expertise includes:
                      </h3>
                      <ol>
                        <li>
                          <p>
                            Intuitive design and seamless functionality for both
                            iOS and Android.
                          </p>
                        </li>
                        <li>
                          <p>Integration with embedded hardware and sensors.</p>
                        </li>
                        <li>
                          <p>
                            Support for in-app purchases, subscriptions, and
                            monetization strategies.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="postbox__quote-2 mb-50">
                    <blockquote>
                      <h4>
                        "Software is a great combination between artistry and
                        engineering."
                      </h4>
                      <span>- Bill Gates</span>
                    </blockquote>
                  </div>

                  <div class="product__details-content">
                    <div class="product__tab mb-40">
                      <ul class="nav nav-tabs" id="proTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="overview-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#overview"
                            type="button"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                          >
                            Overview
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="support-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#support"
                            type="button"
                            role="tab"
                            aria-controls="support"
                            aria-selected="false"
                          >
                            Support
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="product__tab-content">
                      <div class="tab-content" id="proTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="overview"
                          role="tabpanel"
                          aria-labelledby="overview-tab"
                        >
                          <div class="product__overview">
                            <h3 class="product__overview-title">
                              Our Vision & Commitment
                            </h3>
                            <p class="drop-cap">
                              At the core of our software development process is
                              a commitment to quality, innovation, and customer
                              satisfaction. From ideation to deployment, our
                              team ensures that every line of code adds value to
                              your project. Together, we’ll build solutions that
                              redefine possibilities.
                            </p>
                            <ServiceSMCard />
                          </div>
                        </div>
                        <ServiceSupport />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ServiceSidebar />
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default DevSoft;
